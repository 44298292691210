import React from 'react'

const Hamburger = () => {
    return(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="4rem"
    height="24"
    viewBox="0 0 52 24"
  >
    <g id="Group_9" data-name="Group 9" transform="translate(-294 -47)">
      <rect
        id="Rectangle_3"
        data-name="Rectangle 3"
        width="3.5rem"
        height="4"
        rx="2"
        transform="translate(307 47)"
        fill="#000"
      />
      <rect
        id="Rectangle_5"
        data-name="Rectangle 5"
        width="3.5rem"
        height="4"
        rx="2"
        transform="translate(307 57)"
        fill="#000"
      />
      <rect
        id="Rectangle_4"
        data-name="Rectangle 4"
        width="3.5rem"
        height="4"
        rx="2"
        transform="translate(307 67)"
        fill="#000"
      />
    </g>
  </svg>
  )
}

export default Hamburger