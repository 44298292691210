import React from 'react'

const BlogsPage = () => {
  return (
    <div className='Blogs temp'>
      <div className="container">
        <h1 className='ta-cen uc'>Blogs</h1>
      </div>
    </div>
  )
}

export default BlogsPage