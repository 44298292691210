import React from 'react'
import Footer from '../components/Footer/Footer'

const footerPage = () => {
  return (
    <div>
      <Footer />
    </div>
  )
}

export default footerPage